const HTTP_URL=process.env.VUE_APP_API+'/index/'
module.exports = {
  hd_info:`${HTTP_URL}wechat/hdInfo`,
  hd_userList:`${HTTP_URL}wechat/userList`,
  getuserinfo:`${HTTP_URL}wechat/user/info`,//用户活动信息

  good:`${HTTP_URL}good/list`,
  goodinfo:`${HTTP_URL}good/info`,
  goodorder:`${HTTP_URL}good/order`,

  //需授权
  orderlist:`${HTTP_URL}user/orderlist`,
  orderCreate:`${HTTP_URL}user/orderCreate`,
  orderInfo:`${HTTP_URL}user/orderInfo`,
  orderCancel:`${HTTP_URL}user/orderCancel`,
  orderHx:`${HTTP_URL}user/orderHx`,

  //佣金
  commission:`${HTTP_URL}wechat/user/commission`,
  cash:`${HTTP_URL}wechat/user/cash`,
  applycash:`${HTTP_URL}wechat/user/applycash`,
  //客服
  kefu:`${HTTP_URL}wechat/kefu`,
}
