import {
  hd_info,hd_userList,kefu,
  good,goodinfo,goodorder,
  orderlist,orderCreate,orderInfo,orderCancel,orderHx,
  getuserinfo,
  commission,cash,applycash
} from './api'
import {request, METHOD} from '@/utils/request'
//活动基本信息
export async function GetHdInfo(params) {
  return request(hd_info, METHOD.POST,params)
}
//客服
export async function GetKefuInfo(params) {
  return request(kefu, METHOD.POST,params)
}

//参与用户
export async function GetuserList(params) {
  return request(hd_userList, METHOD.POST,params)
}
//用户活动信息
export async function Gethduinfo(params) {
  return request(getuserinfo, METHOD.POST,params)
}
//商品列表
export async function GetgoodList(params) {
  return request(good, METHOD.POST,params)
}
//商品信息
export async function GetgoodInfo(params) {
  return request(goodinfo, METHOD.POST,params)
}
//用户订单列表
export async function GetuserorderList(params) {
  return request(goodorder, METHOD.POST,params)
}

//我的订单列表
export async function GetorderList(params) {
  return request(orderlist, METHOD.POST,params)
}
//创建订单
export async function OrderCreate(params) {
  return request(orderCreate, METHOD.POST,params)
}

//订单详情
export async function GetorderInfo(params) {
  return request(orderInfo, METHOD.POST,params)
}

//取消订单
export async function OrderCancel(params) {
  return request(orderCancel, METHOD.POST,params)
}
//核销
export async function OrderHx(params) {
  return request(orderHx, METHOD.POST,params)
}

//佣金
export async function GetCommission(params) {
  return request(commission, METHOD.POST,params)
}
export async function GetCash(params) {
  return request(cash, METHOD.POST,params)
}
export async function ApplyCash(params) {
  return request(applycash, METHOD.POST,params)
}
