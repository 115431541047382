<template>
<div>
</div>
</template>
<script>
  import wx from 'weixin-js-sdk'
  import {GetjsapiSignature} from '@/services/services'
  import {GetHdInfo, Gethduinfo} from '../services/services'
  import {mapGetters, mapMutations} from "vuex";
export default {
  props: {
    hdid:Number,
    data:Object
  },
  components: {},
  data() {
    return {
      parent_id:undefined,
	    
    }
  },
  computed:{
    ...mapGetters(['userInfo','hdInfo','hduserInfo']),
  },
  created(){
    const {utocode} = this.$route.query
    let _utocode=utocode==undefined?undefined:parseInt(utocode)
    this.parent_id=_utocode==this.userInfo.id?undefined:_utocode
  },
  mounted() {
    if(this.userInfo == null){
      return false
    }
    //指定跳转
	  if(parseInt(process.env.VUE_APP_PRODUCTION)){
      let currentUrl=location.protocol+'//'+location.hostname
      if(this.$route.path.indexOf('/wx/cart/') !== -1 || this.$route.path.indexOf('/wx/order/detail/') !== -1){
        let pay_url=process.env.VUE_APP_PAY_URL
        if(currentUrl != pay_url){
          location.href = pay_url+location.pathname+location.search
          return false
        }
      }else{
        if(this.hdInfo != null){
         
	        let url = this.hdInfo.url
	        if(currentUrl != url){
	          //指定域名
	          if(this.hdInfo.is_appoint){
	            location.href = url+location.pathname+location.search
	            return false
	          }
	          //判断是否同源
	          let arr1 = url.split("//")
	          let u1=arr1[0]+'//'+arr1[1].split(".").filter((item,index) => index !== 0).join('.')
	          let u2=location.protocol+'//'+location.hostname.split(".").filter((item,index) => index !== 0).join('.')
	          if(u1 != u2){
	            location.href = url+location.pathname+location.search
	            return false
	          }
	        }
        }
      }
	  }

	  if(this.hduserInfo == null && this.hdid != undefined){
      this.finduinfo()
	  }
	  if(this.hdInfo == null && this.hdid != undefined){
	    this.findhdInfo()
	  }
		if(this.$route.path != '/finish'){
		  if(this.hdInfo != null){
        let time = new Date(this.hdInfo.end_time).getTime() - new Date().getTime();
        if(time<0 && this.hdInfo.is_colse){
          location.href = '/finish'
        }
		  }
		}
		
    this.getConfig()
  },
  methods: {
    ...mapMutations(['sethduserInfo','sethdInfo']),
    finduinfo(){
      Gethduinfo({hdid:this.hdid,parent_id:this.parent_id}).then(result => {
        if(result.data.code==1){
          this.sethduserInfo(result.data.data)
        }else{
          this.$toast(result.data.msg)
        }
      })
    },
    findhdInfo(){
      GetHdInfo({hdid:this.hdid}).then(result => {
        if(result.data.code==1){
          this.sethdInfo(result.data.data)
        }else{
          this.$toast(result.data.msg)
        }
      })
    },
    getConfig(){
      if(this.hduserInfo == null || this.hdInfo == null){
        setTimeout(()=>{
          this.getConfig()
        },1000)
	      return false
      }
      let jsApiList=[]
      if(this.data==undefined){
        jsApiList=[
          'hideMenuItems'
        ]
      }else{
        jsApiList=[
          'hideMenuItems',
          'updateTimelineShareData',
          'updateAppMessageShareData',
          'onMenuShareAppMessage', //旧的接口，即将废弃
          'onMenuShareTimeline',//旧的接口，即将废弃
          'openLocation'
        ]
      }
      GetjsapiSignature({jsApiList:jsApiList,url:location.href}).then(result => {
        if(result.data.code==1){
          const {appId,nonceStr,timestamp,signature,jsApiList} = result.data.data
          if(this.data==undefined){
            wx.config({
              // debug: true,
              appId: appId,
              timestamp: timestamp,
              nonceStr: nonceStr,
              signature: signature,
              jsApiList:jsApiList
            });
            wx.ready(()=>{
              wx.hideMenuItems({
                menuList: [
                  "menuItem:share:appMessage",
                  "menuItem:share:timeline",
                  "menuItem:exposeArticle",
                  "menuItem:copyUrl",
                  "menuItem:openWithQQBrowser",
                  "menuItem:openWithSafari",
                  "menuItem:share:email",
                  "menuItem:setFont",
                  "menuItem:readMode",
                  "menuItem:exposeArticle",
                  "menuItem:share:qq",
                  "menuItem:share:QZone",
                ]
              });
            });
          }else{
            wx.config({
              // debug: true,
              appId: appId,
              timestamp: timestamp,
              nonceStr: nonceStr,
              signature: signature,
              jsApiList:jsApiList
            });
            var wxData = {
              title:this.data.title,
              desc:this.data.desc,
              link:this.data.link+((this.userInfo==null||this.userInfo==undefined)?'':('?utocode='+this.userInfo.id))+((this.hduserInfo.qrcode!=''&&this.hduserInfo.is_kefu==1)?('&kefu_id='+this.userInfo.id):''),
              imgUrl:this.data.imgurl,
              success: function (res) {
              },
              cancel: function (res) {
                // 用户取消分享后执行的回调函数
              }
            }
            //监听分享动作
            wx.ready(()=>{
              wx.hideMenuItems({
                menuList: [
                  "menuItem:copyUrl",
                  "menuItem:openWithQQBrowser",
                  "menuItem:openWithSafari",
                  "menuItem:share:email",
                  "menuItem:setFont",
                  "menuItem:readMode",
                  "menuItem:exposeArticle",
                  "menuItem:share:qq",
                  "menuItem:share:QZone"
                ]
              });
              //分享朋友圈
              wx.updateTimelineShareData(wxData);
              //发送给朋友
              wx.updateAppMessageShareData(wxData);
            });
          }
        }
        
      })
    }
  }
}
</script>
<style lang="less" scoped>

</style>
